<template>
  <div class="home  home_pc">

    <section id="section-1" class="section-content no-padding">
      <div class="head-row">
        <div class="app-intro">
          <h1 class="section-1-rule" v-html="$global.formatstr(doc.s1.h1, month)"></h1>


          <p class="writter-content">
            Look no further than GB WhatsApp for a chat programme that combines the best features of
            WhatsApp and more. This software is a popular third-party WhatsApp mod that provides
            additional functionality and customization possibilities, making it popular among
            millions of users around the world. GB WhatsApp has you covered if you want more
            privacy, greater control over your chatting experience, or just a way to add some fun
            and flair to your interactions. So, let's get started and look at all of the fantastic
            features that make GB WhatsApp the must-have chatting programme for anyone trying to up
            their messaging game!
          </p>

          <lazysource :source="require('@/assets/gb/New_banner@2x.webp')"
            :img="require('@/assets/gb/New_banner@2x.webp')" classname="writer-banner"></lazysource>

        </div>
      </div>

      <ApkDetail :doc="doc" />

      <DownloadBtn class="top-download fix has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" />

      <h2 class="intro-title intro-title-1"><span @click="gotochat()"
          class="cursor-point">GBWhatsApp</span> vs WhatsApp – What's The Difference?</h2>

      <p class="intro-item intro-item-2" v-html="doc.s1.intro_2_new"></p>

      <table id="playlist" cellspacing="0">
        <tbody>
          <tr>
            <th style="width:29%">Feature</th>
            <th>GBWhatsApp</th>
            <th>WhatsApp</th>
          </tr>
          <tr v-for="(data, i) in feature" :key="i">
            <td>
              <div v-html="data.feature"></div>
            </td>
            <td>
              <div v-html="data.gb"></div>
            </td>
            <td>
              <div v-html="data.whs"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <S2 :doc="doc" />

    <S3 :doc="doc" :pageName="pageName" />

  </div>
</template>

<script>
// @ is an alias to /src
import "@/css/gb/pc.scss";
import DownloadBtn from "@/components/DownloadBtnNew.vue";
import S2 from "../assets/section2new.vue";
import S3 from "../assets/section3.vue";
import ApkDetail from "../assets/apkdetail.vue";
import lazysource from "@/components/lazysource.vue";

export default {
  name: "pc",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    lazysource,
  },
  data () {
    return {
      pageName: "gb",
      doc: null,
      myApp: null,
      from: "gb",
      filename: "gb",
      cooper: [
        {
          name: "ActualPost",
          link: "https://actualpost.com/gb-whatsapp-download/",
        },
        {
          name: "mpatel.org",
          link: "https://mpatel.org/gbwhatsapp-download/",
        },
        {
          name: "https://softmany.com/",
          link: "https://softmany.com/",
        },
        { name: "APKPosts.com", link: "https://apkposts.com/" },
      ],
      feature: [
        {
          feature: "Hide Online Status",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Airplane Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Status Download",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Add Custom Fonts/Stickers",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Media Sharing",
          gb: "Maximum 200MB",
          whs: "Maximum 15MB",
        },
        {
          feature: "Status Character Length",
          gb: "Maximum 255",
          whs: "Maximum 139",
        },
        {
          feature: "Themes Supported",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "DND Mode",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Freeze Last Seen",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable Forwarded Tag",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Disable/Customize Calling",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Anti-Delete Status/Messages",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Security Lock",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Fully Customize",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Auto Reply",
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: "Increase Forward Limit",
          gb: "Maximum 250",
          whs: "Maximum 10",
        },
        {
          feature: "Increase Image Share Limit",
          gb: "Maximum 100",
          whs: "Maximum 30",
        },
      ],
      moreItem: [
        {
          name: "vml",
          icon: require("@/assets/gb/vml.png"),
          advertise: "Status Downloader",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/Status-Downloader.apk",
        },
        {
          name: "play",
          icon: require("@/assets/gb/play.png"),
          advertise: "WhatsApp Player",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Player.apk",
        },
        {
          name: "clean",
          icon: require("@/assets/gb/clean.png"),
          advertise: "WhatsApp Cleaner",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Cleaner-2.2.04.apk",
        },
        {
          name: "wall",
          icon: require("@/assets/gb/wall.png"),
          advertise: "WhatsApp Wallpaper",
          apk: "https://cdn.gbwhatsapp.download/app/android/apk/WhatsApp-Wallpaper.apk",
        },
      ],

      viewHeight: null,
      scrollEventFun: null,
      month: "",

      showTeleMsg: true,
      isOffical: 0,

    };
  },
  created () {
    this.myApp = this.$store.state.gb;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    console.log(this.myApp);
    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    this.$logEvent(`show_gbhome_pc`);

    console.log(this.$route);
  },
  mounted () {
    console.log("mounted");
    setTimeout(() => {
      if (typeof window !== "undefined") {
        this.viewHeight = window.innerHeight;
        this.scrollEvent();
        // 监听scroll事件
        this.scrollEventFun = this.$global.throttle(
          this.scrollEvent,
          300
        );
        window.addEventListener("scroll", this.scrollEventFun, false);
      }

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },

  beforeDestroy () {
    // 防止内存溢出
    window.removeEventListener("scroll", this.scrollEventFun, false);
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      let nodeArr = document.querySelectorAll(".has-exp");
      // console.log($scrollTop);
      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    download () {
      // this.$global.download(this.myApp.apk);
      this.$logEvent("click_gwhome_download");
      this.$router.push({
        name: "gb_download_pc",
        params: { apk: this.myApp.apk },
      });
    },
    downloadad (name, apk) {
      this.$global.download(apk);
      this.$logEvent(`click_gwhome_${name}_download`);
    },
    gotoTele () {
      window.location.href = "https://t.me/gb_download";
    },
    gotochat () {
      window.location.href = "https://www.whatsplus.online/";
    },
  },
  metaInfo () {
    return {
      title: `GBWhatsApp Download APK (Updated) ${this.$root.month} 2024 – Official Latest (Anti-Ban)`,
      script: [
        {
          innerHTML: `
					{
						"@context": "https://schema.org",
						"@type": "SoftwareApplication",
						"name": "GBWhatsApp APK",
						"operatingSystem": "ANDROID",
						"applicationCategory": "LifestyleApplication",
						"aggregateRating": {
							"@type": "AggregateRating",
							"ratingValue": "${this.$store.state.gb.rate}",
							"bestRating": "5",
							"worstRating": "1",
							"ratingCount": "${Math.floor(this.$store.state.gb.clicks / 10.75)}"
						},
						"offers": {
							"@type": "Offer",
							"price": "0",
							"priceCurrency": "USD"
						},
						"review": {
							"@type": "Review",
							"author": {
								"@type": "Person",
								"name": "Hankson"
							},
							"datePublished": "2024-04-16",
							"reviewRating": {
								"@type": "Rating",
								"ratingValue": "${this.$store.state.gb.rate}",
								"bestRating": "5",
								"worstRating": "1"
							}
						}
					}
				`,
          type: "application/ld+json",
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      meta: [
        {
          name: "keywords",
          content:
            "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download",
        },
        {
          name: "image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
        {
          name: "og:title",
          content: `GBWhatsApp APK Download (Anti-Ban) ${this.$root.month} 2024 | Official`,
        },
        {
          name: "og:url",
          content: window.location.href,
        },
        {
          name: "og:image",
          content: `https://cdn.gbwhatsapp.download/img/icon_GB_new@2x.3474f003.webp`,
        },
        {
          name: "og:description",
          content: `Download GBWhatsApp APK 2024 latest version. It's the BEST way to support OFFICIAL WhatsApp with extra features. Gb WhatsApp is 100% free & Anti-Ban.`,
        },
      ],
      // link: [
      // 	{
      // 		rel: "canonical",
      // 		href: `https://gbapksdownload.com/gbwhatsapp.html/`,
      // 	},
      // ],
    };
  },
};
</script>
